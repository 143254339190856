import { useState } from "react";
import MovieCard from "./MovieCard";
import moviesDataJSON from "../movies-data.json";
import AddMovie from "./AddMovie";
import FilterMovies from "./FilterMovies";

const MovieList = () => {
  const [movies, setMovies] = useState(moviesDataJSON);
  const [moviesData, setMoviesData] = useState(moviesDataJSON);

  const addNewMovie = (movie) => {
    const updatedMovies = [...movies, movie];
    const updatedMoviesData = [...moviesData, movie];

    setMovies(updatedMovies);
    setMoviesData(updatedMoviesData);
  };

  const filterMovieList = (str) => {
    let filteredMovies;

    if (str === "All") {
      filteredMovies = moviesData;
    } else {
      filteredMovies = moviesData.filter((movie) => {
        return movie.title[0].toLowerCase() === str.toLowerCase();
      });
    }
    setMovies(filteredMovies);
  };

  return (
    <div>
      <FilterMovies filterMovies={filterMovieList} />
      <AddMovie AddMovie={addNewMovie} />
      <h2>Movies List</h2>
      {movies.map((movie) => {
        return <MovieCard key={movie.title} movie={movie} />;
      })}
    </div>
  );
};

export default MovieList;
